import React from "react"
import { graphql } from "gatsby"

import '../css/image-hero.css';

const ImageHero = ({ image, title, copy }) => {
  var divStyle = {};

  if( image && image.node && image.node.localFile ) {
    divStyle.backgroundImage = 'url(' + image.node.localFile.publicURL + ')';
  }

  return (
    <div className="container defaultHero imageHero" style={divStyle}>
      <div className="grid smallGrid">
        <div className="col-sm-12">
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <p>{copy}</p>
          <div className="dots">
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
            <span>&#9679;</span>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ImageHero